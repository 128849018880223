import { Avatar, Button, FormControl } from '@mui/material';
import './InAppWalletsDetailsPage.scss';
import { gql } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import {
  getAmountWithCurrency,
  getNameAvatar,
  getVehicleSeats,
} from '../../utils/functions';
import Colors from '../../constants/Colors';
import TransactionListItem from '../../components/ListItem/TransactionListItem';
import { gBaseUrl } from '../../utils/config';
import { useGetUserDetailsById } from '../../hooks/gql/query/getById/useGetUserDetailsById';
import { useGetLeaderboardPositionByUserId } from '../../hooks/gql/query/useGetLeaderboardPositionByUserId';
import { useGetAllTransactionsByUserIdByPage } from '../../hooks/gql/query/getAll/useGetAllTransactionsByUserIdByPage';
import { useGenerateAndSendDuePaymentPdf } from '../../hooks/gql/mutation/action/useGenerateAndSendDuePaymentPdf';
import { useState } from 'react';

const GET_USER_DETAILS_BY_ID = gql`
  query GetUserDetailsById($input: MongoIdInput!) {
    getUserDetailsById(input: $input) {
      _id
      company_name
      photo
      isPhotoVisible
      referral_code
      total_earning
      pending_wallet_balance
      final_wallet_balance
      link_refer_earning
      total_referrals
      business_address
      total_cancellations
      vehicle_type
      phone
      contact_name
      email
      contact_name
    }
  }
`;

const GET_USER_LEADERBOARD_POSITION = gql`
  query GetLeaderboardPositionByUserId($input: MongoIdInput!) {
    getLeaderboardPositionByUserId(input: $input) {
      _id
      position
      points
    }
  }
`;

const GET_ALL_TRANSACTIONS_BY_USER_ID = gql`
  query GetAllTransactionsByUserIdByPage($input: PaginationWithIdInput!) {
    getAllTransactionsByUserIdByPage(input: $input) {
      _id
      amount
      transaction_status
      transaction_token
      transaction_type
      createdAt
      mode
    }
  }
`;

const InAppWalletsDetailsPage: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { generateAndSendDuePaymentPdf } = useGenerateAndSendDuePaymentPdf();

  const {
    data: user,
    error: userError,
    loading: userLoading,
    refetch: userRefectch,
  } = useGetUserDetailsById(GET_USER_DETAILS_BY_ID, {
    _id: slug || '64f8267c7ed1f6aa0166bde1',
  });

  const {
    data: leaderboard_position,
    error: leaderboard_positionError,
    loading: leaderboard_positionLoading,
    refetch: leaderboard_positionRefectch,
  } = useGetLeaderboardPositionByUserId(GET_USER_LEADERBOARD_POSITION, {
    _id: slug || '64f8267c7ed1f6aa0166bde1',
  });

  const {
    data: transactions,
    error: transactionsError,
    loading: transactionsLoading,
    refetch: transactionsRefectch,
  } = useGetAllTransactionsByUserIdByPage(GET_ALL_TRANSACTIONS_BY_USER_ID, {
    _id: slug || '64f8267c7ed1f6aa0166bde1',
    page: 1,
    item_per_page: 3,
  });

  return (
    <>
      <div className='inapp_wallet_details_wrapper'>
        <div className='inapp_wallet_details_section'>
          {user && (
            <>
              <div className='inapp_wallet_details_section_header'>
                <div className='title_logo'>
                  {user.isPhotoVisible ? (
                    <Avatar
                      src={`${gBaseUrl}/photos/avatars/${user.photo}`}
                      sx={{
                        bgcolor: Colors.pclXLight,
                        marginRight: 1,
                        color: Colors.primaryColorLight,
                        boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        width: 40,
                        height: 40,
                        bgcolor: Colors.pclXLight,
                        marginRight: 1,
                        color: Colors.primaryColorLight,
                        boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                      }}>
                      {getNameAvatar(user.company_name)}
                    </Avatar>
                  )}

                  <div className='title_logo_text'>
                    <p className='business_name' style={{ fontWeight: 'bold' }}>
                      {user?.company_name}
                    </p>
                    <p>#{user.referral_code}</p>
                  </div>
                </div>

                <div className='title_stats'>
                  <p>Rank: #{leaderboard_position?.position}</p>
                  <p>Booking Cancelled: {user.total_cancellations}</p>
                </div>
              </div>

              <div className='inapp_wallet_details_section_det1'>
                <div className='inapp_wallet_details_section_det1_p1'>
                  <div className=''>
                    <p>Wallet Balance</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {getAmountWithCurrency(user.final_wallet_balance)}
                    </p>
                  </div>

                  <div className=''>
                    <p>Pending Balance</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {user.pending_wallet_balance}
                    </p>
                  </div>
                </div>
                <div className='inapp_wallet_details_section_det1_p2'>
                  <div className=''>
                    <p>Total Earnings</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {getAmountWithCurrency(user.total_earning)}
                    </p>
                  </div>

                  <div className=''>
                    <p>Phone</p>
                    <p style={{ fontWeight: 'bold' }}>{user.phone}</p>
                  </div>
                </div>
              </div>
              <div className='inapp_wallet_details_section_det2'>
                <div className='inapp_wallet_details_section_det2_p1'>
                  <div className=''>
                    <p>Contact Name</p>
                    <p style={{ fontWeight: 'bold' }}>{user.contact_name}</p>
                  </div>

                  <div className=''>
                    <p>Email</p>
                    <p style={{ fontWeight: 'bold' }}>{user.email}</p>
                  </div>
                </div>
                <div className='inapp_wallet_details_section_det2_p2'>
                  <div className=''>
                    <p>Company Address</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {user.business_address}
                    </p>
                  </div>

                  <div className=''>
                    <p>Vehicle Capacity</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {getVehicleSeats(user.vehicle_type)}
                    </p>
                  </div>
                </div>
              </div>

              <div className='inapp_wallet_details_section_images'>
                <div className='cds_image'></div>

                <div className='cds_actions'>
                  <FormControl sx={{ m: 1, width: '15ch' }} variant='standard'>
                    <Button
                      variant='contained'
                      disabled={user.final_wallet_balance >= 0 || isSubmitting}
                      size='small'
                      color='info'
                      onClick={async () => {
                        setIsSubmitting(true);
                        try {
                          const res = await generateAndSendDuePaymentPdf({
                            _id: user._id,
                          });

                          if (res.generateAndSendDuePaymentPdf) {
                            alert('Request sent successfully!');
                          } else {
                            throw new Error(JSON.stringify(res.error));
                          }
                        } catch (err) {
                          alert('Something went wrong!');
                          console.log(err);
                        }
                        setIsSubmitting(false);
                      }}>
                      <p
                        style={{
                          marginTop: 3,
                          color: '#fff',
                          cursor: 'pointer',
                        }}>
                        {isSubmitting ? 'Please wait...' : ' Request Deposit'}
                      </p>
                    </Button>
                  </FormControl>
                </div>
              </div>
            </>
          )}
        </div>

        <div className='transactions__section_wrapper'>
          <div className='transactions_section'>
            <div className='transactions_header'>
              <h2>Transactions</h2>
              <div
                className='transactions_header_action'
                onClick={() => {
                  navigate(`/in-app-wallets/${slug}/transactions`);
                }}>
                <p>View All</p>
                <ChevronRightOutlinedIcon style={{ color: '#47C56C' }} />
              </div>
            </div>

            <div className='transactions_cards'>
              {transactions && transactions.length === 0 && (
                <p style={{ textAlign: 'center', marginTop: 10 }}>
                  No Transactions yet
                </p>
              )}
              {transactions &&
                transactions.map((p) => {
                  return (
                    <TransactionListItem
                      key={p._id}
                      _id={p._id}
                      transaction_type={p.transaction_type}
                      mode={p.mode}
                      amount={p.amount}
                      createdAt={p.createdAt}
                      status={p.transaction_status}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <div className='footer_section'></div>
    </>
  );
};
export default InAppWalletsDetailsPage;
