import { Avatar, Button, FormControl } from '@mui/material';
import './UsersApprovalDetailsPage.scss';
import { gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Colors from '../../constants/Colors';
import { gBaseUrl } from '../../utils/config';
import { getNameAvatar, formatString } from '../../utils/functions';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { useGetUserDetailsById } from '../../hooks/gql/query/getById/useGetUserDetailsById';
import { useUpdateUser } from '../../hooks/gql/mutation/update/useUpdateUser';
import { useDeleteUserAvatar } from '../../hooks/gql/mutation/delete/useDeleteUserAvatar';
import { useDeleteUserVP } from '../../hooks/gql/mutation/delete/useDeleteUserVP';

const GET_USER_DETAILS_BY_ID = gql`
  query GetUserDetailsById($input: MongoIdInput!) {
    getUserDetailsById(input: $input) {
      _id
      company_name
      referral_code
      contact_name
      phone
      email
      business_address

      photo
      photoStatus
      isPhotoVisible

      vehiclePictures
      vpStatus
      isVPVisible

      templateMessage
      templateStatus
      isTemplateVisible
    }
  }
`;

const UsersApprovalDetailsPage: React.FC<{}> = (props) => {
  const { slug } = useParams();
  const [userId, setUserId] = useState('64f8267c7ed1f6aa0166bde1');

  const { updateUser } = useUpdateUser();
  const { deleteUserAvatar } = useDeleteUserAvatar();
  const { deleteUserVP } = useDeleteUserVP();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [zoomImage, setZoomImage] = useState(false);
  const [zoomedImage, setzoomedImage] = useState('');

  const {
    data: user,
    error: userError,
    loading: userLoading,
    refetch: userRefectch,
  } = useGetUserDetailsById(GET_USER_DETAILS_BY_ID, {
    _id: userId,
  });

  useEffect(() => {
    if (slug) {
      setUserId(slug);
    }
  }, [slug]);

  return (
    <>
      <div className='templates_details_wrapper'>
        <div className='template_details_section'>
          <div className='template_details_section_header'>
            <div className='title_logo'>
              {user?.isPhotoVisible ? (
                <Avatar
                  src={`${gBaseUrl}/photos/avatars/${user?.photo}`}
                  sx={{
                    bgcolor: Colors.pclXLight,
                    marginRight: 1,
                    color: Colors.primaryColorLight,
                    boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                  }}
                />
              ) : (
                <Avatar
                  sx={{
                    bgcolor: Colors.pclXLight,
                    marginRight: 1,
                    color: Colors.primaryColorLight,
                    boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                  }}>
                  {getNameAvatar(user?.company_name || 'User')}
                </Avatar>
              )}
              <div className='title_logo_info'>
                <p className='business_name'>{user?.company_name}</p>
                <p>ID: #{user?.referral_code}</p>
              </div>
            </div>
            <div className='temp_status'>
              <p>{formatString(user?.templateStatus)}</p>
            </div>
          </div>

          <div className='customer_details_wrapper'>
            <h2>Company Info</h2>
            <div className='template_details_section_det3'>
              <div className='template_details_section_det3_p1'>
                <div className=''>
                  <p>Contact Name</p>
                  <p style={{ fontWeight: 'bold' }}>{user?.contact_name}</p>
                </div>

                <div className=''>
                  <p>Phone</p>
                  <p
                    style={{
                      fontWeight: 'bold',
                    }}>
                    {user?.phone}
                  </p>
                </div>
              </div>
              <div className='template_details_section_det3_p2'>
                <div className=''>
                  <p>Email</p>
                  <p
                    style={{
                      fontWeight: 'bold',
                    }}>
                    {user?.email}
                  </p>
                </div>

                <div className=''>
                  <p>Company Address</p>
                  <p
                    style={{
                      fontWeight: 'bold',
                    }}>
                    {user?.business_address}
                  </p>
                </div>
              </div>
            </div>

            <div className='template_details_section_det4'>
              <div className='template_details_section_det4_p1'>
                <div className=''>
                  <h2>Template Message</h2>

                  <pre style={{ marginTop: 10 }}>{user?.templateMessage}</pre>
                </div>
              </div>
              <div className='template_details_section_det4_p2'>
                <div
                  className=''
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <FormControl sx={{ m: 1, width: '22ch' }} variant='standard'>
                    <Button
                      variant='contained'
                      size='small'
                      disabled={user?.isTemplateVisible}
                      color='primary'
                      onClick={async () => {
                        setIsSubmitting(true);
                        try {
                          await updateUser({
                            _id: userId,
                            isTemplateVisible: true,
                            templateStatus: 'approved',
                          });

                          setIsSubmitting(false);
                          userRefectch();
                        } catch (err) {
                          console.log(err);
                          setIsSubmitting(false);
                        }
                      }}>
                      <p style={{ marginTop: 3, color: '#fff' }}>
                        {isSubmitting ? 'Wait...' : 'Approve'}
                      </p>
                    </Button>
                  </FormControl>

                  <FormControl sx={{ m: 1, width: '22ch' }} variant='standard'>
                    <Button
                      variant='contained'
                      size='small'
                      disabled={!user?.isTemplateVisible}
                      color='error'
                      onClick={async () => {
                        setIsSubmitting(true);

                        await updateUser({
                          _id: userId,
                          isTemplateVisible: false,
                          templateStatus: 'rejected',
                        });

                        try {
                          await updateUser({
                            _id: userId,
                            isTemplateVisible: false,
                            templateStatus: 'rejected',
                          });
                          setIsSubmitting(false);
                          userRefectch();
                        } catch (err) {
                          setIsSubmitting(false);
                          console.log(err);
                        }
                      }}>
                      <p style={{ marginTop: 3, color: '#fff' }}>
                        {isSubmitting ? 'Wait...' : 'Reject'}
                      </p>
                    </Button>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>

          {user?.photo && user?.photo !== 'user-default.jpg' && (
            <div className='vp_images_wrapper'>
              <div className='vpd_images'>
                <div className='vpd_image_item'>
                  <div
                    className='vpd_image_action'
                    onClick={async () => {
                      try {
                        await deleteUserAvatar({
                          _id: user._id,
                        });
                        userRefectch();
                        setIsSubmitting(false);
                      } catch (e) {
                        console.error(e);
                        setIsSubmitting(false);
                      }
                    }}>
                    <DeleteForeverIcon color='error' />
                  </div>
                  <img src={`${gBaseUrl}/photos/avatars/${user?.photo}`} />
                  <div
                    className='zoom_image'
                    onClick={() => {
                      setzoomedImage(
                        `${gBaseUrl}/photos/avatars/${user?.photo}`
                      );
                      setZoomImage(true);
                    }}>
                    <ZoomOutMapIcon fontSize='large' color='info' />
                  </div>
                </div>
              </div>

              <div className='vp_images_action'>
                <div className='vp_images_action_status'>
                  <p>{formatString(user.photoStatus)}</p>
                </div>
                <div className='vp_images_action_button'>
                  <FormControl sx={{ m: 1, width: '16ch' }} variant='standard'>
                    <Button
                      variant='contained'
                      size='small'
                      disabled={
                        (user?.isPhotoVisible &&
                          user.photoStatus === 'approved') ||
                        user.photoStatus === 'not_set'
                      }
                      onClick={async () => {
                        try {
                          await updateUser({
                            _id: userId,
                            isPhotoVisible: true,
                            photoStatus: 'approved',
                          });

                          userRefectch();
                          setIsSubmitting(false);
                        } catch (e) {
                          console.error(e);
                          setIsSubmitting(false);
                        }
                      }}>
                      Approve Profile Picture
                    </Button>
                  </FormControl>
                </div>
              </div>

              <Modal
                open={zoomImage}
                onClose={() => {
                  setZoomImage(false);
                }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}>
                <Fade in={zoomImage}>
                  <Box
                    sx={{
                      position: 'absolute' as const,
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '80%',
                      bgcolor: Colors.light,
                      boxShadow: 24,
                      borderRadius: 2,
                      p: 0.5,
                    }}>
                    <div className='zoomed_image_modal'>
                      <div
                        className='zoomed_image_action'
                        onClick={async () => {
                          setZoomImage(false);
                        }}>
                        <CloseIcon color='info' />
                      </div>
                      <img src={zoomedImage} />
                    </div>
                  </Box>
                </Fade>
              </Modal>
            </div>
          )}

          <div className='vp_images_wrapper'>
            <div className='vpd_images'>
              {user?.vehiclePictures?.map((vp: string) => {
                return (
                  <div className='vpd_image_item' key={vp}>
                    <div
                      className='vpd_image_action'
                      onClick={async () => {
                        try {
                          await deleteUserVP({
                            _id: user._id,
                            filename: vp,
                          });
                          userRefectch();
                          setIsSubmitting(false);
                        } catch (e) {
                          console.error(e);
                          setIsSubmitting(false);
                        }
                      }}>
                      <DeleteForeverIcon color='error' />
                    </div>
                    <img src={`${gBaseUrl}/photos/vehiclePictures/${vp}`} />
                    <div
                      className='zoom_image'
                      onClick={() => {
                        setzoomedImage(
                          `${gBaseUrl}/photos/vehiclePictures/${vp}`
                        );
                        setZoomImage(true);
                      }}>
                      <ZoomOutMapIcon fontSize='large' color='info' />
                    </div>
                  </div>
                );
              })}
            </div>

            <div className='vp_images_action'>
              <div className='vp_images_action_status'>
                <p>{formatString(user?.vpStatus)}</p>
              </div>
              <div className='vp_images_action_button'>
                <FormControl sx={{ m: 1, width: '16ch' }} variant='standard'>
                  <Button
                    variant='contained'
                    size='small'
                    disabled={
                      (user?.isVPVisible && user.vpStatus === 'approved') ||
                      user?.vpStatus === 'not_set'
                    }
                    onClick={async () => {
                      try {
                        await updateUser({
                          _id: userId,
                          isVPVisible: true,
                          vpStatus: 'approved',
                        });

                        userRefectch();
                        setIsSubmitting(false);
                      } catch (e) {
                        console.error(e);
                        setIsSubmitting(false);
                      }
                    }}>
                    Approve Vehicle Images
                  </Button>
                </FormControl>
              </div>
            </div>

            <Modal
              open={zoomImage}
              onClose={() => {
                setZoomImage(false);
              }}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}>
              <Fade in={zoomImage}>
                <Box
                  sx={{
                    position: 'absolute' as const,
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    bgcolor: Colors.light,
                    boxShadow: 24,
                    borderRadius: 2,
                    p: 0.5,
                    zIndex: 999,
                  }}>
                  <div className='zoomed_image_modal'>
                    <div
                      className='zoomed_image_action'
                      onClick={async () => {
                        setZoomImage(false);
                      }}>
                      <CloseIcon color='info' />
                    </div>
                    <img src={zoomedImage} />
                  </div>
                </Box>
              </Fade>
            </Modal>
          </div>
        </div>
      </div>

      <div className='footer_section'></div>
    </>
  );
};
export default UsersApprovalDetailsPage;
