import './SettingsPage.scss';
import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CurrencyPoundOutlinedIcon from '@mui/icons-material/CurrencyPoundOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { gql } from '@apollo/client';
import { useGetSetting } from '../../hooks/gql/query/useGetSetting';
import { useUpdateSetting } from '../../hooks/gql/mutation/update/useUpdateSetting';
import InputField from '../../components/Input/InputField';

const GET_SETTINGS = gql`
  query GetSetting {
    getSetting {
      _id
      upfrontPercentage
      getCoverPrice
      maxHoldingAmount
      maxHoldingDays
      maxNegBalance
      maxPosBalPayout
      maxTimeIntvForPayout
      maxTimeToPayNegative
      sendTarget
    }
  }
`;

const SettingsPage: React.FC<{}> = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState({
    upfrontPercentage: 0,
    getCoverPrice: 0,
    maxHoldingAmount: 0,
    maxHoldingDays: 0,
    maxNegBalance: 0,
    maxPosBalPayout: 0,
    maxTimeIntvForPayout: 0,
    maxTimeToPayNegative: 0,
    sendTarget: 0, // Set as 0 or 1
  });

  const {
    data: settings,
    error: settingsError,
    loading: settingsLoading,
    refetch: settingsRefectch,
  } = useGetSetting(GET_SETTINGS);

  const { updateSetting } = useUpdateSetting();

  useEffect(() => {
    if (settings) {
      setFormValues({
        upfrontPercentage: settings.upfrontPercentage || 0,
        getCoverPrice: settings.getCoverPrice || 0,
        maxHoldingAmount: settings.maxHoldingAmount || 0,
        maxHoldingDays: settings.maxHoldingDays || 0,
        maxNegBalance: settings.maxNegBalance || 0,
        maxPosBalPayout: settings.maxPosBalPayout || 0,
        maxTimeIntvForPayout: settings.maxTimeIntvForPayout || 0,
        maxTimeToPayNegative: settings.maxTimeToPayNegative || 0,
        sendTarget: settings.sendTarget ? 1 : 0, // Convert boolean to number
      });
    }
  }, [settingsLoading]);

  const handleInputChange = (field: string, value: string) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: parseFloat(value) || 0,
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (settings) {
      try {
        await updateSetting({
          _id: settings._id,
          upfrontPercentage:
            +formValues.upfrontPercentage || settings.upfrontPercentage,
          getCoverPrice: +formValues.getCoverPrice || settings.getCoverPrice,
          maxHoldingAmount:
            +formValues.maxHoldingAmount || settings.maxHoldingAmount,
          maxHoldingDays: +formValues.maxHoldingDays || settings.maxHoldingDays,
          maxNegBalance: +formValues.maxNegBalance || settings.maxNegBalance,
          maxPosBalPayout:
            +formValues.maxPosBalPayout || settings.maxPosBalPayout,
          maxTimeIntvForPayout:
            +formValues.maxTimeIntvForPayout || settings.maxTimeIntvForPayout,
          maxTimeToPayNegative:
            +formValues.maxTimeToPayNegative || settings.maxTimeToPayNegative,
          sendTarget: formValues.sendTarget === 1, // Convert number to boolean
        });
        alert('Changed Saved! Thanks');
        settingsRefectch();
        setIsSubmitting(false);
      } catch (err) {
        console.log(err);
        setIsSubmitting(false);
      }
    }

    setIsSubmitting(false);
  };

  return (
    <div className='settings_wrapper'>
      <div className='left_input_section'>
        <div className='logo_wrapper-settings'>
          <p className='welcom_text'>Configure Fully Booked</p>
        </div>

        <div className='input_fields'>
          <InputField
            label='Commission (%)'
            value={formValues.upfrontPercentage.toString()}
            placeholder='Commission'
            onChange={(value) => handleInputChange('upfrontPercentage', value)}
            icon={<PercentOutlinedIcon style={{ color: '#324A59' }} />}
          />

          <InputField
            label='Get Cover Fee'
            value={formValues.getCoverPrice.toString()}
            placeholder='Get Cover Fee'
            onChange={(value) => handleInputChange('getCoverPrice', value)}
            icon={<CurrencyPoundOutlinedIcon style={{ color: '#324A59' }} />}
          />

          <InputField
            label='Max Holding Amount'
            value={formValues.maxHoldingAmount.toString()}
            placeholder='Max Holding Amount'
            onChange={(value) => handleInputChange('maxHoldingAmount', value)}
            icon={<CurrencyPoundOutlinedIcon style={{ color: '#324A59' }} />}
          />

          <InputField
            label='Max Holding Days'
            value={formValues.maxHoldingDays.toString()}
            placeholder='Max Holding Days'
            onChange={(value) => handleInputChange('maxHoldingDays', value)}
            icon={<AccessTimeIcon style={{ color: '#324A59' }} />}
          />

          <InputField
            label='Max Negative Balance'
            value={formValues.maxNegBalance.toString()}
            placeholder='Max Negative Balance'
            onChange={(value) => handleInputChange('maxNegBalance', value)}
            icon={<CurrencyPoundOutlinedIcon style={{ color: '#324A59' }} />}
          />

          <InputField
            label='Max Positive Balance Payout'
            value={formValues.maxPosBalPayout.toString()}
            placeholder='Max Positive Balance Payout'
            onChange={(value) => handleInputChange('maxPosBalPayout', value)}
            icon={<CurrencyPoundOutlinedIcon style={{ color: '#324A59' }} />}
          />

          <InputField
            label='Max Time Interval for Payout (days)'
            value={formValues.maxTimeIntvForPayout.toString()}
            placeholder='Max Time Interval for Payout'
            onChange={(value) =>
              handleInputChange('maxTimeIntvForPayout', value)
            }
            icon={<AccessTimeIcon style={{ color: '#324A59' }} />}
          />

          <InputField
            label='Max Time to Pay Negative Balance (days)'
            value={formValues.maxTimeToPayNegative.toString()}
            placeholder='Max Time to Pay Negative Balance'
            onChange={(value) =>
              handleInputChange('maxTimeToPayNegative', value)
            }
            icon={<AccessTimeIcon style={{ color: '#324A59' }} />}
          />

          <InputField
            label='Send Notification to Target Companies [Set Values (0 = No, 1 = Yes)]'
            value={formValues.sendTarget.toString()}
            placeholder='Send Targeted Notification'
            onChange={(value) => handleInputChange('sendTarget', value)}
            icon={
              <NotificationsNoneOutlinedIcon style={{ color: '#324A59' }} />
            }
          />

          <FormControl sx={{ m: 1 }} variant='standard' className='input_fc'>
            <Button
              className='input_fc_item'
              variant='contained'
              size='large'
              onClick={handleSubmit}>
              {isSubmitting ? 'Please wait...' : 'Save Changes'}
            </Button>
          </FormControl>

          <div style={{ marginBottom: 200 }} />
        </div>
      </div>

      <div className='right_image_section'>
        <img
          src='/images/percentage.png'
          alt='Computer Desk'
          className='computer_desk_img'
        />
      </div>
    </div>
  );
};
export default SettingsPage;
