import { Avatar } from '@mui/material';
import Colors from '../../constants/Colors';
import './GetCoversDetailsPage.scss';
import { gql } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  formatString,
  getNameAvatar,
  getVehicleType,
} from '../../utils/functions';
import CompanyListItem from '../../components/ListItem/CompanyListItem';
import { gBaseUrl } from '../../utils/config';
import { useGetCoverDetailsById } from '../../hooks/gql/query/getById/useGetCoverDetailsById';

const GET_COVER_DETAILS = gql`
  query GetCoverDetailsById($input: MongoIdInput!) {
    getCoverDetailsById(input: $input) {
      _id
      status
      asked_by {
        _id
        referral_code
        company_name
        photo
        isPhotoVisible
      }

      cover_to {
        _id
        name
        email
        phone_number
      }

      booked_company_ref {
        _id
        referral_code
        company_name
        contact_name
        email
        phone
        photo
        isPhotoVisible
        business_address
      }
      proposals {
        _id
        agreed_amount
        proposed_by {
          _id
          company_name
          phone
          photo
          isPhotoVisible
          referral_code
        }
      }
      trip_info {
        pickup_location {
          cords
          location_name
        }
        destination {
          cords
          location_name
        }
        tripType
        pickup_datetime
        return_datetime
        passenger_count
        travelling_reason
        luggage_type
        vehicle_count
        vehicle_type
        note
      }
    }
  }
`;

const GetCoversDetailsPage: React.FC<{}> = (props) => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const {
    data: cover,
    error: coverError,
    loading: coverLoading,
    refetch: coverRefectch,
  } = useGetCoverDetailsById(GET_COVER_DETAILS, {
    _id: slug || '64f8267c7ed1f6aa0166bde1',
  });

  const trip_info = cover?.trip_info;
  const proposals = cover?.proposals || [];
  const asked_by = cover?.asked_by;
  const booked_company = cover?.booked_company_ref;
  const cover_to = cover?.cover_to;

  return (
    <>
      <div className='cover_details_wrapper'>
        <div className='cover_details_section'>
          <div className='cover_details_section_header'>
            <div className='generated'>
              <p>Asked By</p>
            </div>
            <div className='title_logo'>
              {asked_by?.isPhotoVisible ? (
                <Avatar
                  src={`${gBaseUrl}/photos/avatars/${asked_by?.photo}`}
                  sx={{
                    width: 40,
                    height: 40,
                    bgcolor: Colors.pclXLight,
                    marginRight: 1,
                    color: Colors.primaryColorLight,
                    boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                  }}
                />
              ) : (
                <Avatar
                  sx={{
                    width: 40,
                    height: 40,
                    bgcolor: Colors.pclXLight,
                    marginRight: 1,
                    color: Colors.primaryColorLight,
                    boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                  }}>
                  {getNameAvatar(asked_by?.company_name || 'User')}
                </Avatar>
              )}
              <div className='title_logo_info'>
                <p className='business_name'>{asked_by?.company_name}</p>
                <p>ID: #{asked_by?.referral_code}</p>
              </div>
            </div>
          </div>

          <div className='journey_details_wrapper'>
            <h2>Journey Details</h2>
            <div className='cover_details_section_det0'>
              <div className='cover_details_section_det0_p1'>
                <div className=''>
                  <p>Pickup Location</p>
                  <p style={{ fontWeight: 'bold' }}>
                    {trip_info?.pickup_location?.location_name || '--'}
                  </p>
                </div>

                <div className=''>
                  <p>Destination</p>
                  <p style={{ fontWeight: 'bold' }}>
                    {trip_info?.destination?.location_name || '--'}
                  </p>
                </div>
              </div>
            </div>

            <div className='cover_details_section_det1'>
              <div className='cover_details_section_det1_p1'>
                <div className=''>
                  <p>Vehicle Count</p>
                  <p style={{ fontWeight: 'bold' }}>
                    {trip_info?.vehicle_count || 'N/A'}
                  </p>
                </div>

                <div className=''>
                  <p>Luggage Type</p>
                  <p style={{ fontWeight: 'bold' }}>
                    {formatString(trip_info?.luggage_type) || 'N/A'}
                  </p>
                </div>
              </div>
              <div className='cover_details_section_det1_p2'>
                <div className=''>
                  <p>Pickup Date</p>
                  <p style={{ fontWeight: 'bold' }}>
                    {moment(trip_info?.pickup_datetime).format('lll')}
                  </p>
                </div>

                <div className=''>
                  <p>Return Date</p>
                  <p style={{ fontWeight: 'bold' }}>
                    {trip_info?.return_datetime
                      ? moment(trip_info?.return_datetime).format('lll')
                      : '---'}
                  </p>
                </div>
              </div>
            </div>
            <div className='cover_details_section_det2'>
              <div className='cover_details_section_det2_p1'>
                <div className=''>
                  <p>Reason for Travel</p>
                  <p style={{ fontWeight: 'bold' }}>
                    {formatString(trip_info?.travelling_reason) || 'N/A'}
                  </p>
                </div>

                <div className=''>
                  <p>Status</p>
                  <p
                    style={{
                      fontWeight: 'bold',
                      color: Colors.primaryColorLight,
                    }}>
                    {formatString(cover?.status)}
                  </p>
                </div>
              </div>
              <div className='cover_details_section_det2_p2'>
                <div className=''>
                  <p>Passenger Count</p>
                  <p style={{ fontWeight: 'bold' }}>
                    {trip_info?.passenger_count}
                  </p>
                </div>

                <div className=''>
                  <p>Vehicle Type</p>
                  <p
                    style={{
                      fontWeight: 'bold',
                    }}>
                    {getVehicleType(trip_info?.vehicle_type || '')}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='customer_details_wrapper'>
            <h2>Customer Details</h2>
            <div className='cover_details_section_det3'>
              <div className='cover_details_section_det3_p1'>
                <div className=''>
                  <p>Customer Name</p>
                  <p style={{ fontWeight: 'bold' }}>{cover_to?.name}</p>
                </div>

                <div className=''>
                  <p>Phone</p>
                  <p
                    style={{
                      fontWeight: 'bold',
                    }}>
                    {cover_to?.phone_number}
                  </p>
                </div>
              </div>
              <div className='cover_details_section_det3_p2'>
                <div className=''>
                  <p>Email</p>
                  <p
                    style={{
                      fontWeight: 'bold',
                    }}>
                    {cover_to?.email}
                  </p>
                </div>
              </div>
            </div>

            <div className='cover_details_section_det4'>
              <div className='cover_details_section_det4_p1'>
                <div className=''>
                  <p>Message</p>
                  <p style={{ marginTop: 10 }}>
                    {trip_info?.note || 'No additional notes'}
                  </p>
                </div>
              </div>
              <div className='cover_details_section_det4_p2'>
                <div className=''></div>
              </div>
            </div>
          </div>

          {booked_company && (
            <div className='customer_details_wrapper'>
              <h2>Booked By</h2>
              <div className='cover_details_section_det4'>
                <div className='cover_details_section_det4_p1'>
                  <div className=''>
                    <p>Company Name</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {booked_company.company_name}
                    </p>
                  </div>

                  <div className=''>
                    <p>Contact Name</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {booked_company.contact_name}
                    </p>
                  </div>

                  <div className=''>
                    <p>Phone</p>
                    <p
                      style={{
                        fontWeight: 'bold',
                      }}>
                      {booked_company.phone}
                    </p>
                  </div>
                </div>
              </div>

              <div className='cover_details_section_det4'>
                <div className='cover_details_section_det4_p1'>
                  <div className=''>
                    <p>Email</p>
                    <p
                      style={{
                        fontWeight: 'bold',
                      }}>
                      {booked_company.email}
                    </p>
                  </div>

                  <div className=''>
                    <p>Business Address</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {booked_company.business_address}
                    </p>
                  </div>

                  <div className=''>
                    <p>Contact ID</p>
                    <p
                      style={{
                        fontWeight: 'bold',
                      }}>
                      #{booked_company.referral_code}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='appliedcomp_section_wrapper'>
          <div className='appliedcomp_section'>
            <h2>Applied Companies</h2>

            <div className='appliedcomp_cards'>
              {proposals?.length === 0 && (
                <p style={{ textAlign: 'center' }}>No proposal sent yet</p>
              )}
              {proposals &&
                proposals.map((p) => {
                  return (
                    <div
                      key={p._id}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        navigate(
                          `/get-covers/${slug}/conversations?pCID=${p.proposed_by.phone}`
                        );
                      }}>
                      <CompanyListItem
                        _id={p._id}
                        company_name={p.proposed_by.company_name}
                        referral_code={p.proposed_by.referral_code}
                        photo={p.proposed_by.photo}
                        isPhotoVisible={p.proposed_by.isPhotoVisible}
                        counter={p.agreed_amount}
                        isAmount={true}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <div className='footer_section'></div>
    </>
  );
};
export default GetCoversDetailsPage;
